import CountUp from "react-countup";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper";

export default function Client() {
  return (
    <div className="mt-8">
      <div className="pt-72">
        <div className="w-[70%] my-0 mx-auto absolute left-[16%] -mt-52 bg-white">
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col lg:flex-row w-full items-start lg:items-center rounded bg-white shadow">
              <div className="w-full h-[22rem] bg-white z-10 border-2 border-gray-200 shadow-lg rounded-md">
                <div className="absolute top-[40%] left-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 298.667 298.667"
                    className="w-24 h-24"
                    fill="#d1d5db"
                  >
                    <g>
                      <polygon points="0,170.667 64,170.667 21.333,256 85.333,256 128,170.667 128,42.667 0,42.667 "></polygon>
                      <polygon points="170.667,42.667 170.667,170.667 234.667,170.667 192,256 256,256 298.667,170.667 298.667,42.667 "></polygon>
                    </g>
                  </svg>
                </div>
                <div className="absolute top-10 right-10 z-50">
                  <button className="btn btn-primary bg-black hover:bg-pink-600 text-white text-xs font-semibold py-2 px-2 testimonial-swiper-button-prev">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z"
                      />
                    </svg>
                  </button>
                  <button className="ml-2 btn btn-primary bg-black hover:bg-pink-600 text-white text-xs font-semibold py-2 px-2 testimonial-swiper-button-next">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z"
                      />
                    </svg>
                  </button>
                </div>
                <Swiper
                  centeredSlides={true}
                  grabCursor={true}
                  navigation={{
                    nextEl: ".testimonial-swiper-button-next",
                    prevEl: ".testimonial-swiper-button-prev",
                  }}
                  autoplay={{
                    delay: 4000,
                  }}
                  loop={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="flex">
                      <img
                        src="https://images.unsplash.com/photo-1477118476589-bff2c5c4cfbb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                        alt=""
                        className="hidden md:block p-8 w-[24rem] h-[22rem]"
                      />
                      <div className="pt-12 mr-2 md:mr-24">
                        <p className="pl-6 md:pl-0 py-2 uppercase text-pink-600 font-semibold">
                          Our Testimonial
                        </p>
                        <p className="text-2xl font-bold pl-6 md:pl-0">
                          What Clients Says
                        </p>
                        <p className="py-4 text-sm md:text-md pl-6 md:pl-0">
                          The entire Cross Angle team was truly wonderful to
                          work with. They fully understood our needs and gave us
                          our dream home! They also made the process interesting
                          along the way and we trusted them completely. They
                          truly are the best interior designers and would
                          recommend surely!
                        </p>
                        <p className="text-xl font-semibold pl-6 md:pl-0">
                          Ms. Malini Bose
                        </p>
                        <p className="text-sm pl-6 md:pl-0">Project Manager</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex">
                      <img
                        src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1148&q=80"
                        alt=""
                        className="hidden md:block p-8 w-[24rem] h-[22rem]"
                      />
                      <div className="pt-12 mr-2 md:mr-24">
                        <p className="pl-6 md:pl-0 py-2 uppercase text-pink-600 font-semibold">
                          Our Testimonial
                        </p>
                        <p className="text-2xl font-bold pl-6 md:pl-0">
                          What Clients Says
                        </p>
                        <p className="py-4 text-sm md:text-md pl-6 md:pl-0">
                          Cross Angle has a great team, and friendly relations,
                          they designed the ideal home for my needs. It is a
                          fantastic project, we are very happy! They were
                          originally recommended to us by a friend and now we
                          recommend them to everyone.
                        </p>
                        <p className="text-xl font-semibold pl-6 md:pl-0">
                          Mr. Niranjan Kapoor
                        </p>
                        <p className="text-sm pl-6 md:pl-0">Design Manager</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex">
                      <img
                        src="https://images.unsplash.com/photo-1489980478712-2ab535aa775f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                        alt=""
                        className="hidden md:block p-8 w-[24rem] h-[22rem]"
                      />
                      <div className="pt-12 mr-2 md:mr-24">
                        <p className="pl-6 md:pl-0 py-2 uppercase text-pink-600 font-semibold">
                          Our Testimonial
                        </p>
                        <p className="text-2xl font-bold pl-6 md:pl-0">
                          What Clients Says
                        </p>
                        <p className="py-4 text-sm md:text-md pl-6 md:pl-0">
                          Mr. Srikant Sharma and his team were very easy to work
                          with. They took an effort to find out our preferred
                          style and colour choices. Everything was handled very
                          professionally by his team of experts. We are really
                          enjoying our new house and have received lots of
                          compliments. Thanks to the whole team.
                        </p>
                        <p className="text-xl font-semibold pl-6 md:pl-0">
                          Mr. Dhruv Verma
                        </p>
                        <p className="text-sm pl-6 md:pl-0">Design Manager</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex">
                      <img
                        src="https://images.unsplash.com/photo-1509839862600-309617c3201e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                        alt=""
                        className="hidden md:block p-8 w-[24rem] h-[22rem]"
                      />
                      <div className="pt-12 mr-2 md:mr-24">
                        <p className="pl-6 md:pl-0 py-2 uppercase text-pink-600 font-semibold">
                          Our Testimonial
                        </p>
                        <p className="text-2xl font-bold pl-6 md:pl-0">
                          What Clients Says
                        </p>
                        <p className="py-4 text-sm md:text-md pl-6 md:pl-0">
                          I was planning to renovate and redecorate my office
                          space for a long time. I searched and read many
                          reviews about cross angle and connected them
                          immediately. I am a little picky about my choices but
                          I am glad that I had them. I would definitely
                          recommend Mr. Srikant Sharma and his team to everyone.
                        </p>
                        <p className="text-xl font-semibold pl-6 md:pl-0">
                          {" "}
                          Mrs. Inaya Sharma
                        </p>
                        <p className="text-sm pl-6 md:pl-0">Design Manager</p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[url('https://images.pexels.com/photos/3178786/pexels-photo-3178786.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')] bg-cover w-full h-96  photo">
          {/* <img src="/public/assets/images/3.jpeg"/> */}
          <section class="text-white body-font  w-[80%] my-0 mx-auto pt-[10vh]">
            <div class="px-5 py-24 mx-auto">
              <div class="flex md:flex -mt-8 -ml-20 md:ml-0 text-center">
                <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
                  <div class="hover:border-2 hover:border-gray-600 px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="text-white w-10 h-10 mb-3 inline-block"
                      viewBox="0 0 24 24"
                    >
                      <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                    </svg>
                    <h2 class="title-font font-medium sm:text-3xl text-sm text-white">
                      <CountUp start={0} end={26} duration={3} />
                      k+
                    </h2>
                    <p class="leading-relaxed sm:text-3xl text-sm text-white">
                      Happy Customers
                    </p>
                  </div>
                </div>
                <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
                  <div class="hover:border-2 hover:border-gray-600 px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="text-white w-10 h-10 mb-3 inline-block"
                      viewBox="0 0 24 24"
                    >
                      <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                    </svg>
                    <h2 class="title-font font-medium sm:text-3xl text-sm text-white">
                      <CountUp start={0} end={22} duration={3} />
                      k+
                    </h2>
                    <p class="leading-relaxed sm:text-3xl text-sm text-white">
                      Happy Clients
                    </p>
                  </div>
                </div>
                <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
                  <div class="hover:border-2 hover:border-gray-600 px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="text-white w-10 h-10 mb-3 inline-block"
                      viewBox="0 0 24 24"
                    >
                      <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                    </svg>
                    <h2 class="title-font font-medium sm:text-3xl text-sm text-white">
                      <CountUp start={0} end={28} duration={3} />
                      k+
                    </h2>
                    <p class="leading-relaxed sm:text-3xl text-sm text-white">
                      Happy Comers
                    </p>
                  </div>
                </div>
                <div class="p-4 md:w-1/4 sm:w-1/2 w-full">
                  <div class="hover:border-2 hover:border-gray-600 px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="text-white w-10 h-10 mb-3 inline-block"
                      viewBox="0 0 24 24"
                    >
                      <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                    </svg>
                    <h2 class="title-font font-medium sm:text-3xl text-sm text-white">
                      <CountUp start={0} end={29} duration={3} />
                      k+
                    </h2>
                    <p class="leading-relaxed sm:text-3xl text-sm text-white">
                      Happy Customers
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
