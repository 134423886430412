import { Routes, Route } from "react-router-dom";
import {
  HomePage,
  ContactPage,
  AboutPage,
  ServicePage,
  TeamPage,
  BlogPage,
  PlanningPage,
  InteriorPage,
  ExteriorPage,
  TrunkeyPage,
  ConsulationPage,
  MiniaturePage,
} from "./pages/index";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/service" element={<ServicePage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/planning" element={<PlanningPage />} />
        <Route path="/interior" element={<InteriorPage />} />
        <Route path="/exterior" element={<ExteriorPage />} />
        <Route path="/trunkey" element={<TrunkeyPage />} />
        <Route path="/consulation" element={<ConsulationPage />} />
        <Route path="/miniature" element={<MiniaturePage />} />
      </Routes>
    </div>
  );
}

export default App;
