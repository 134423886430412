import React from "react";
import "./Interior.css";
export default function Interior() {
  return (
    <div>
      <div className="-mt-64 Interior">
        <h1> Exterior</h1>
      </div>
      <div class="container">
        <div className="flex flex-col items-center justify-center mt-16">
          <h1 className="text-4xl font-bold leading-9 text-center text-slate-900">
            Our Exterior Designs
          </h1>
          <p className="text-base font-semibold leading-normal sm:text-center text-justify text-gray-800 mt-4 lg:w-1/2 md:w-10/12 w-11/12">
            We transform the exterior that reflects the personality of the
            residents and workspace. Cross Angle Interiors offers a range of
            exterior-based services that allow you to transform your property in
            order to reflect your own personality. We also offer interior design
            and spacious living spaces for your convenience.{" "}
          </p>
        </div>
        <br />
        <br />
        <div class="gallery">
          <div class="gallery-item">
            <img
              class="gallery-image"
              src="assets/images/7c01f9c9-1018-4c73-b8b3-4d036f0f5e1f.jpeg"
              alt=""
            />
          </div>

          <div class="gallery-item">
            <img
              class="gallery-image"
              src="assets/images/187060068_4151996211523102_1780793583960844905_n.jpg"
              alt=""
            />
          </div>

          <div class="gallery-item">
            <img
              class="gallery-image"
              src="assets/images/WhatsApp Image 2021-10-01 at 6.33.42 PM.jpeg"
              alt=""
            />
          </div>

          <div class="gallery-item">
            <img
              class="gallery-image"
              src="assets/images/katihar.jpeg"
              alt=""
            />
          </div>

          <div class="gallery-item">
            <img class="gallery-image" src="assets/images/houses.jpeg" alt="" />
          </div>

          <div class="gallery-item">
            <img
              class="gallery-image"
              src="assets/images/WhatsApp .jpeg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
