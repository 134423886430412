import Navbar from "../../components/Navbar/Navbar";
import Trunkey from "../../components/Trunkey/Trunkey";
import Footer from "../../components/Footer/Footer";

function TrunkeyPage() {
    return (
        <div className="TrunkeyPage">
        <Navbar />
        <div className="py-40">
            <Trunkey />
        </div>
        <div className="md:-mt-52 -mt-52 ">
        <Footer />
        </div>
        </div>
    );
    }

export default TrunkeyPage;