import Navbar from "../../components/Navbar/Navbar";
import Miniature from "../../components/Miniature/Miniature";
import Footer from "../../components/Footer/Footer";

function MiniaturePage() {
    return (
        <div className="MiniaturePage">
        <Navbar />
       
            <div className="py-40">
            <Miniature />
            </div>
            <div className="md:-mt-52 -mt-52 ">
        <Footer />
        </div>
        </div>
    );
    }

export default MiniaturePage;