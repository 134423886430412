import Navbar from "../../components/Navbar/Navbar";
import Interior from "../../components/Interior/Interior";
import Footer from "../../components/Footer/Footer";

function InteriorPage() {
  return (
    <div className="ServicePage">
      <Navbar />
      <div className="">
      <div className=" py-40">
        <Interior />
      </div>
     
      <Footer />
   
    </div>
    </div>
  );
}

export default InteriorPage;