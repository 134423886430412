import './Miniature.css';
import React from 'react';

export default function Miniature() {
  return (
    <div>
    <div className="-mt-64 Interior">
     <h1>    Miniature Model</h1>
     </div>
     
     <section class="about-plan -mt-10">
         <div class="container">
        
             <div class="row" style={{display:"grid", gridTemplateColumns:"0.55fr 1fr"}}>                
                 <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                     <div class="inner-column -ml-44 md:ml-auto">
                         <div class="plan-title">
                             {/* <span class="plan-title font-bold text-sky-900">What We Plan</span> */}
                             <h2>Custom designs <br/>for your custom tastes</h2>
                         </div>
                         <div class="text" style={{fontWeight:"600", color:"slategray"}}>Our company specializes in creating 3D miniature models for our clients as per the requirements. 
        These models are often used to describe an area or as a stand-in for a real room while taking pictures. 
        Our company provides these services to customers all over India. </div>
                         <ul class="list-style-one">
                             <li>Customized 3D model</li>
                             <li>Prepared with perfection</li>
                             <li>Innovative, creative, and stylish</li>
                         </ul>
                         <div class="btn-box">
                             <a href="#!" class="theme-btn btn-style-one">Contact Us</a>
                         </div>
                     </div>
                 </div>
 
                
                 <div class="image-column col-lg-6 col-md-12 col-sm-12">
                     <div class="inner-column wow fadeInLeft hidden md:flex">
                         <figure class="image-1"><a href="#!" class="lightbox-image" data-fancybox="images"><img src="assets/images/187060068_4151996211523102_1780793583960844905_n.jpg" alt="" style={{borderRadius:"10px", boxShadow:"2px 2px 4px gray"}}/></a></figure>
                         <figure class="image-2"><a href="#!" class="lightbox-image" data-fancybox="images"><img src="https://4.imimg.com/data4/NE/EA/MY-2796576/residential-miniature-models-500x500.jpg" alt="" width="350px" style={{borderRadius:"10px", boxShadow:"2px 2px 4px gray"}}/></a></figure>
                     </div>
                 </div>
             </div>
         </div>
     </section>
         </div>
  )
}
