import Navbar from "../../components/Navbar/Navbar";
import Aboutus from "../../components/About/Aboutus";
import Footer from "../../components/Footer/Footer";

function AboutPage() {
  return (
    <div className="AboutPage">
      <Navbar />
      <div className="py-40">
        <Aboutus />
      </div>
      <div className="md:-mt-40 -mt-44 ">
        <Footer />
        </div>
    </div>
  );
}

export default AboutPage;
