import "./Footer.css";

function Footer() {
  return (
    <footer>
      <div class="footer -mt-16">
        <h1>
          <img className="logo" src="../../assets/images/logo.png" alt=""></img>
          CrossAngle Interior
        </h1>
        <div class="fax">
          <a href="https://www.facebook.com/caisrikant">
            <i class="fa fa-facebook"></i>
          </a>
          <a href="https://www.instagram.com/crossangle_interior/https://www.instagram.com/crossangle_interior/">
            <i class="fa fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/86596830/admin/">
            <i class="fa fa-linkedin"></i>
          </a>
          <a href="https://in.pinterest.com/crossangledigital">
            <i class="fa fa-pinterest"></i>
          </a>
          <a href="https://twitter.com/CrossangleInt">
            <i class="fa fa-twitter"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCFHaKoRg9VV7zS6-7Lnmp3g">
            <i class="fa fa-youtube"></i>
          </a>
        </div>

        <div class="fax">
          <ul>
            <li>
              <a href="#!">Contact us</a>
            </li>
            <li>
              <a href="#!">Our Services</a>
            </li>
            <li>
              <a href="#!">Privacy Policy</a>
            </li>
            <li>
              <a href="#!">Terms & Conditions</a>
            </li>
            <li>
              <a href="#!">Career</a>
            </li>
          </ul>
        </div>

        <div class="fax b">
          Copyright © 2021 CrossAngleInterior - All rights reserved || Designed
          By: Shashank
        </div>
      </div>
    </footer>
  );
}
export default Footer;
