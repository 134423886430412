import Navbar from "../../components/Navbar/Navbar";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";

function ContactPage() {
  return (
    <div className="ContactPage">
      <Navbar />
      
        <div className="py-40">
          <Contact />
        </div>
      
      <Footer />
    </div>
  );
}

export default ContactPage;
