import Navbar from "../../components/Navbar/Navbar";
import Planning from "../../components/Planning/Planning";
import Footer from "../../components/Footer/Footer";

function PlanningPage() {
    return (
        <div className="PlanningPage">
        <Navbar />
       
            <div className="py-40">
            <Planning />
            </div>
            <div className="md:-mt-52 -mt-52 ">
        <Footer />
        </div>
        </div>
    );
    }

export default PlanningPage;