import Navbar from "../../components/Navbar/Navbar";
import Home from "../../components/Home/Home";
import About from "../../components/About/About";
import Design from "../../components/Design/Design";
import What from "../../components/What/What";
import Client from "../../components/Client/Client";
import Service from "../../components/Service/Service";
import Blog from "../../components/Blog/Blog";
import Partner from "../../components/Partner/Partner";
import Footer from "../../components/Footer/Footer";
import "./HomePage.css";
import $ from "jquery";

function HomePage() {
  /* FadeIn Scroll */
  $(document).ready(function () {
    /* Every time the window is scrolled ... */
    $(window).scroll(function () {
      /* Check the location of each desired element */
      $(".fade").each(function (i) {
        var bottom_of_object = $(this).position().top + $(this).outerHeight();
        var bottom_of_window = $(window).scrollTop() + $(window).height();

        /* If the object is completely visible in the window, fade it it */
        if (bottom_of_window > bottom_of_object) {
          $(this).animate({ opacity: "1" }, 900);
        }
      });
    });
  });

  return (
    <div>
      <Navbar />
      <Home />
      <div
        className="fade"
        style={{
          position: "fixed",
          top: "92%",
          zIndex: "999",
        }}
      >
        <a href="https://web.whatsapp.com/">
          <img
            // id="header"
            className="bg-gray-100 rounded p-2 border-2 border-gray-300 shadow-lg "
            src="/assets/images/whatsapp.67b545f.png"
            alt="..."
          />
        </a>
      </div>
      <About />
      <Design />
      <What />
      <Service />
      <Partner />
      <Client />
      <Blog />
      <Footer />
    </div>
  );
}

export default HomePage;
