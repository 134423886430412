import "./Design.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import useMediaQuery from "../../hooks/useMediaQuery";

function Design() {
  SwiperCore.use([Autoplay]);
  const isDesktop = useMediaQuery("(min-width: 991px)");
  return (
    <div className="offer">
      <div className="small">
        <p className="font-bold my-6 -mt-24 md:mt-0 text-slate-700 text-5xl text-center latest">
          Latest Design
        </p>
        <div className="row justify-around flex flex-wrap">
          <Swiper
            slidesPerView={isDesktop ? 4 : 1.45}
            centeredSlides={true}
            spaceBetween={10}
            grabCursor={true}
            // navigation={{
            //   nextEl: ".team-swiper-button-next",
            //   prevEl: ".team-swiper-button-prev",
            // }}
            autoplay={{
              delay: 3000,
            }}
            loop={true}
            // modules={[Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div
                className="col-4"
                style={
                  {
                    // transform: "scale(1.15)",
                    // transition: "transform 400ms ease-out",
                  }
                }
              >
                <img src="/assets/images/L1.jpg" alt="" className="offer-img" />
              </div>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="col-4"
                style={
                  {
                    // transform: "scale(1.15)",
                    // transition: "transform 400ms ease-out",
                  }
                }
              >
                <img
                  src="/assets/images/L6.jpeg"
                  alt=""
                  className="offer-img"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="col-4"
                style={
                  {
                    // transform: "scale(1.15)",
                    // transition: "transform 400ms ease-out",
                  }
                }
              >
                <img src="/assets/images/L3.jpg" alt="" className="offer-img" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="col-4"
                style={
                  {
                    // transform: "scale(1.15)",
                    // transition: "transform 400ms ease-out",
                  }
                }
              >
                <img src="/assets/images/L4.jpg" alt="" className="offer-img" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="col-4"
                style={
                  {
                    // transform: "scale(1.15)",
                    // transition: "transform 400ms ease-out",
                  }
                }
              >
                <img
                  src="/assets/images/L5.jpeg"
                  alt=""
                  className="offer-img"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="col-4"
                style={
                  {
                    // transform: "scale(1.15)",
                    // transition: "transform 400ms ease-out",
                  }
                }
              >
                <img
                  src="assets/images/WhatsApp Image 2022-06-07 at 8.47.12 AM.jpeg"
                  alt=""
                  className="offer-img"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="col-4"
                style={
                  {
                    // transform: "scale(1.15)",
                    // transition: "transform 400ms ease-out",
                  }
                }
              >
                <img
                  src="assets/images/WhatsApp Image 2022-01-24 at 6.31.27 PM (1).jpeg"
                  alt=""
                  className="offer-img"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Design;
