import Navbar from "../../components/Navbar/Navbar";
import Consulation from "../../components/Consulation/Consulation";
import Footer from "../../components/Footer/Footer";

function ConsulationPage() {
    return (
        <div className="ConsulationPage">
        <Navbar />
        <div className="py-40">
            <Consulation />
        </div>
        <div className="md:-mt-40 -mt-52 ">
        <Footer />
        </div>
        </div>
    );
    }

export default ConsulationPage;