import "./Consulation.css";
import { useNavigate } from "react-router-dom";

import React from "react";

export default function Consulation() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="-mt-64 Interior">
        <h1> Consultation</h1>
      </div>

      <section class="Consult" id="Consult">
        <div class="container">
          <div class="Consulting text-center font-bold text-sky-900">
            <h2>
              Take Our <span>“Advice”</span>
            </h2>

            <p className="">
              Cross Angle Interior provides you with beautiful, functional and
              sustainable <br />
              interior spaces for both residential and commercial purposes.
            </p>
          </div>

          <div class="row" style={{ display: "flex", padding: "1rem" }}>
            <div class="col-lg-6 -mt-10 md:mt-0">
              <img
                src="https://4.imimg.com/data4/NE/EA/MY-2796576/residential-miniature-models-500x500.jpg"
                alt="about"
                class="img-fluid w-[180rem] md:w-[1200px]"
                style={{
                  height: "450px",
                  borderRadius: "10px",
                  boxShadow: "2px 2px 4px gray",
                }}
              />
            </div>

            <div
              class=" mt-96 col-lg-6 md:-mt-10 -ml-96 md:ml-auto text-justify"
              style={{ padding: "2.5rem" }}
            >
              <h3>We Are The Solution That You Have Dreamed </h3>
              <p>
                Our team of experts provide the best possible consultation as
                per your requirements. Cross Angle Interiors is a one stop
                solution for any interior and exterior design requirement.
                Whether you are looking for lighting, furniture, window
                treatments or flooring, we offer the best interiors at the most
                competitive prices.
              </p>
              <div class="row">
                <div class="col-md-6">
                  <h4>
                    <i class="fa fa-star"></i>Free Consultation
                  </h4>
                </div>
                <div class="col-md-6">
                  <h4>
                    <i class="fa fa-star"></i>
                    Customized solution
                  </h4>
                </div>
                <div class="col-md-6">
                  <h4>
                    <i class="fa fa-star"></i>On-site consultation{" "}
                  </h4>
                </div>
                <div class="col-md-6">
                  <h4>
                    <i class="fa fa-star"></i>
                    Quality with perfect credibility
                  </h4>
                </div>
                <div class="col-md-6">
                  <h4>
                    <i class="fa fa-star"></i>Your choice our priority{" "}
                  </h4>
                </div>
                <div class="col-md-6">
                  <h4>
                    <i class="fa fa-star"></i>Better Result
                  </h4>
                </div>
                <div class="col-md-6">
                  <h4>
                    <i class="fa fa-star"></i>Planning
                  </h4>
                </div>
                <div class="col-md-6">
                  <h4>
                    <i class="fa fa-star"></i>
                    Speed And Flexibility
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row -ml-12  md:ml-4 -mt-10- md:-mt-2" style={{ display: "flex" }}>
            <div class="col-lg-4" onClick={() => navigate("/interior")}>
              <div class="about_content_box_all mt-3">
                <div class="about_detail text-center">
                  <div class="about_icon">
                    <i class="fa fa-pencil"></i>
                  </div>
                  <h5 class="text-dark text-capitalize mt-3 text-xl font-bold">
                    Free Consultation
                  </h5>
                  <p class="edu_desc mt-3 mb-0 font-semibold text-muted">
                    We provide the best possible customized solution at no cost
                  </p>
                </div>
              </div>
            </div>

            <div class="cols-lg-4" onClick={() => navigate("/exterior")}>
              <div class="about_content_box_all mt-3">
                <div class="about_detail text-center">
                  <div class="about_icon">
                    <i class="fa fa-angellist"></i>
                  </div>
                  <h5 class="text-dark text-capitalize mt-3 text-xl font-bold">
                    On-site Consultation
                  </h5>
                  <p class="edu_desc mb-0 mt-3 font-semibold text-muted">
                    We provide in-home consultations and our experts tour the
                    existing space that needs update
                  </p>
                </div>
              </div>
            </div>

            <div class="cols-lg-4">
              <div class="about_content_box_all mt-3">
                <div class="about_detail text-center">
                  <div class="about_icon">
                    <i class="fa fa-paper-plane"></i>
                  </div>
                  <h5 class="text-dark text-capitalize mt-3 text-xl font-bold">
                    Online Consultation
                  </h5>
                  <p class="edu_desc mb-0 mt-3 font-semibold text-muted">
                    We provide online consultation for hassle- free services{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
