import Navbar from "../../components/Navbar/Navbar";
import Team from "../../components/Team/Team";
import Footer from "../../components/Footer/Footer";

function TeamPage() {
  return (
    <div className="AboutPage">
      <Navbar />
      <div className="py-40">
        <Team />
      </div>
      <Footer />
    </div>
  );
}

export default TeamPage;