import Navbar from "../../components/Navbar/Navbar";    
import Exterior from "../../components/Exterior/Exterior";
import Footer from "../../components/Footer/Footer";

function ExteriorPage() {
    return (
        <div className="ExteriorPage">
        <Navbar />
        <div className="py-40">
            <Exterior />
        </div>
        <Footer />
        </div>
    );
    }

export default ExteriorPage;