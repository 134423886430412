import React from 'react';
import './Planning.css';

export default function Planning() {
  return (
    <div>
   <div className="-mt-64 md:-mt-64 Interior">
    <h1>    Planning</h1>
    </div>
    
    <section class="about-plan -mt-10">
        <div class="container">
       
            <div class="row" style={{display:"grid", gridTemplateColumns:"0.55fr 1fr"}}>                
                <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2 ">
                    <div class="inner-column -ml-44 md:ml-auto">
                        <div class="plan-title text-justify">
                            {/* <span class="plan-title font-bold text-sky-900">What We Plan</span> */}
                            <h2>Bringing Great Concepts <br/>To Meet The New Creativity</h2>
                        </div>
                        <div class="text" style={{fontWeight:"600", color:"slategray"}}>We plan creative and inspirational design, catering the need of the client. Our services include a detailed initial consultation, where we will discuss the client's needs and goals, before drawing up a plan of action. 
                        We make sure to take into account budget and deadlines, while designing spaces that are both inspirational and functional..</div>
                        <ul class="list-style-one">
                            <li>Creative and inspirational design</li>
                            <li>Planning catering the needs of the clients</li>
                            <li>On-time planning delivery</li>
                            <li>Bringing great concepts</li>
                        </ul>
                        <div class="btn-box">
                            <a href="#!" class="theme-btn btn-style-one">Contact Us</a>
                        </div>
                    </div>
                </div>

               
                <div class="image-column col-lg-6 col-md-12 col-sm-12 ">
                    <div class="inner-column wow fadeInLeft hidden md:flex">
                        <figure class="image-1"><a href="#!" class="lightbox-image" data-fancybox="images"><img src="assets/images/187060068_4151996211523102_1780793583960844905_n.jpg" alt="" style={{borderRadius:"10px", boxShadow:"2px 2px 4px gray"}}/></a></figure>
                        <figure class="image-2"><a href="#!" class="lightbox-image" data-fancybox="images"><img src="assets/images/4.jpeg" alt="" width="350px" style={{borderRadius:"10px", boxShadow:"2px 2px 4px gray"}}/></a></figure>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </div>
  )
}