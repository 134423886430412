import React from "react";
import "./Home.css";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Thumbs,
  Autoplay,
  EffectCreative,
} from "swiper";

const data = [
  {
    // subtitle: "",
    // title: " ",
    description: "Cross Angle Interiors Where Great Design Is Made",
    button: "Read More",
    image: "assets/images/b1.jpg",
  },
  {
    // subtitle: "",
    // title: " A Design Culture That Transforms Experience",
    description: "Designs That Last A Lifetime Because You Matter To Us ",
    button: "Read More",
    image: "assets/images/b2.jpg",
  },
  {
    // subtitle: "Right Design is Right Around the Corner",
    // title: "",
    description:
      " Every Corner Of Your House Needs A Precious Touch Of The Experts ",
    button: "Read More",
    image: "assets/images/b3.jpg",
  },
  {
    // subtitle: "Pyiiety",
    // title: "Interior For Any Available Site",
    description:
      " Create An Amazing Living Space With Professional Home Designers",
    button: "Read More",
    image: "assets/images/b4.jpg",
  },
];

export default function Home() {
  SwiperCore.use([Autoplay, Navigation, Pagination, Controller, Thumbs]);
  return (
    <>
      <div className="w-full scroll-smooth">
        <Swiper
          navigation
          pagination
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          modules={[Navigation, EffectCreative]}
          grabCursor={true}
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          className="mySwiper"
          autoplay={{
            delay: 5000,
          }}
        >
          {data.map((obj) => {
            return (
              <SwiperSlide key={obj?.image}>
                <div className="h-[70vh] md:h-[90vh] scroll-smooth">
                  <div className="absolute w-full scroll-smooth">
                    <div className="py-[10%] px-[10%] w-full sold text-white md:text-center text-shadow mb-2 h1 font-bold h1-hero scroll-smooth">
                      {/* <p className="text-7xl font-bold uppercase  z-1">
                        {obj?.subtitle}
                      </p>

                      <h1
                        className="has-animation animation-ltr font-bold text-7xl z-1 w-full "
                        data-delay="10"
                      >
                        {obj?.title}
                      </h1> */}
                      <p className="font-bold mt-2 w-full text-5xl z-1 soln">
                        {obj?.description}
                      </p>
                      <button className="mt-10 hero-button  z-1">
                        {obj?.button}
                      </button>
                    </div>
                  </div>

                  <img
                    src={`url(${obj?.image})`}
                    alt="home"
                    className="w-full h-full object-cover"
                    style={{
                      backgroundImage: ` linear-gradient(to bottom, rgba(255,255,255,0) 10%, rgba(0,0,0,0.8) 90%),url(${obj?.image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      scroll: "smooth",
                      backgroundPosition: "center",
                      backgroundAttachment: "fixed",
                    }}
                  />
                  {/* <div
                    
                    className="w-full h-full object-cover matter"
                    style={{
                           src={obj?.image}
                      backgroundImage: `url(${obj?.image})`
                      
                    }}
                  >
                    </div> */}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}
