import React, { useState } from "react";
import "../Interior/Interior.css";
import "./Exterior.css"

export default function Exterior() {
  const [exterior, setExterior] = useState("All Design");

  const toggleExterior = (value) => {
    setExterior(value);
    console.log(value);
  };

  return (
    <div>
      <div className="Exterior">
        <h1> Interior</h1>
      </div>

      <div className="flex flex-col items-center justify-center mt-12">
        <h1 className="text-4xl font-bold leading-9 text-center text-slate-900">
          Our Interior Designs
        </h1>
        <p className="text-base font-semibold leading-normal text-center text-gray-800 mt-4 lg:w-1/2 md:w-10/12 w-11/12">
          We create creative interior design using the available space in the
          best possible way. Cross Angle Interiors is a team of dedicated
          professionals with the experience and expertise to help you realize
          your visions and goals for your project. We believe that space is the
          perfect canvas for our imagination and creativity.{" "}
        </p>
      </div>
      <div className="Exteriors">
        <tab>
          <ul>
            <li className={exterior === "All Design" ? "active" : ""}>
              <p onClick={() => toggleExterior("All Design")}>All Design</p>
            </li>
            <li className={exterior === "Living Room" ? "active" : ""}>
              <p onClick={() => toggleExterior("Living Room")}>Living Room</p>
            </li>
            <li className={exterior === "Bedroom" ? "active" : ""}>
              <p onClick={() => toggleExterior("Bedroom")}>Bedroom</p>
            </li>
            <li className={exterior === "Kitchen" ? "active" : ""}>
              <p onClick={() => toggleExterior("Kitchen")}>Kitchen</p>
            </li>
            <li className={exterior === "Bathroom" ? "active" : ""}>
              <p onClick={() => toggleExterior("Bathroom")}>Bathroom</p>
            </li>
            <li className={exterior === "Office" ? "active" : ""}>
              <p onClick={() => toggleExterior("Office")}>Office</p>
            </li>
            <li className={exterior === "Commercial" ? "active" : ""}>
              <p onClick={() => toggleExterior("Commercial")}>Commercial</p>
            </li>
          </ul>
        </tab>
      </div>
      <div className="alls">
        {(() => {
          if (exterior === "All Design") {
            return (
              <div class="gallery">
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/M B R 4.RGB D.jpg"
                  alt=""
                  data-album="Bedroom"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/WhatsApp Image 2022-01-24 at 6.31.27 PM (1).jpeg"
                  alt=""
                  data-album="Bedroom"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/bed room view 1 chg 3 (sreekant_ji).jpg"
                  alt=""
                  data-album="Bedroom"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/L2.jpg"
                  alt=""
                  data-album="Living Room"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/2a40c4c3-5293-4c87-9333-22381ca2ebed.jpg"
                  alt=""
                  data-album="Living Room"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/c2bc5c00-4334-4ff9-80c7-7387700cb633.jpg"
                  alt=""
                  data-album="Living Room"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/CAM 5 .jpg"
                  alt=""
                  data-album="kitchen"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/WhatsApp Image 2022-06-07 at 8.47.12 AM.jpeg"
                  alt=""
                  data-album="kitchen"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/WhatsApp Image 2021-10-06 at 7.43.45 PM (1).jpeg"
                  alt=""
                  data-album="kitchen"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/WhatsApp Image 2021-04-20 at 10.37.15 AM (1).jpeg"
                  alt=""
                  data-album="Bathroom"
                  />
                </div>
                <div class="gallery-item">
                   <img class="gallery-image"
                  src="assets/images/cmn toilet cam_1.jpg"
                  alt=""
                  data-album="Bathroom"
                  />
                </div>
                <div class="gallery-item">
                   <img class="gallery-image"
                  src="assets/images/Bathroom view 2 (Sreekant_ji) Ajay_ji.jpg"
                  alt=""
                  data-album="Bathroom"
                  />
                </div>
              </div>
            );
          } else if (exterior === "Living Room") {
            return <div class="gallery"><div class="gallery-item"> <img class="gallery-image"
            src="assets/images/L2.jpg"
            alt=""
            data-album="Living Room"
          /></div>
          <div class="gallery-item">
          <img class="gallery-image"
            src="assets/images/2a40c4c3-5293-4c87-9333-22381ca2ebed.jpg"
            alt=""
            data-album="Living Room"
          />
          </div>
          <div class="gallery-item">
          <img class="gallery-image"
            src="assets/images/c2bc5c00-4334-4ff9-80c7-7387700cb633.jpg"
            alt=""
            data-album="Living Room"
          />
          </div>
          </div>;
          } else if (exterior === "Bedroom") {
            return (
              <div className="gallery">
                  <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/M B R 4.RGB D.jpg"
                  alt=""
                  data-album="Bedroom"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/WhatsApp Image 2022-01-24 at 6.31.27 PM (1).jpeg"
                  alt=""
                  data-album="Bedroom"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/bed room view 1 chg 3 (sreekant_ji).jpg"
                  alt=""
                  data-album="Bedroom"
                />
                </div>
              </div>
            );
          } else if (exterior === "Kitchen") {
            return  <div className="gallery">
                <div class="gallery-item">
              <img class="gallery-image"
                  src="assets/images/CAM 5 .jpg"
                  alt=""
                  data-album="kitchen"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/WhatsApp Image 2022-06-07 at 8.47.12 AM.jpeg"
                  alt=""
                  data-album="kitchen"
                />
                </div>
                <div class="gallery-item">
                <img class="gallery-image"
                  src="assets/images/WhatsApp Image 2021-10-06 at 7.43.45 PM (1).jpeg"
                  alt=""
                  data-album="kitchen"
                />
                </div>
              </div>;
          } else if (exterior === "Bathroom") {
            return <div className="gallery">
                <div class="gallery-item">
              <img class="gallery-image"
                  src="assets/images/WhatsApp Image 2021-04-20 at 10.37.15 AM (1).jpeg"
                  alt=""
                  data-album="Bathroom"
                  />
                </div>
                <div class="gallery-item">
                   <img class="gallery-image"
                  src="assets/images/cmn toilet cam_1.jpg"
                  alt=""
                  data-album="Bathroom"
                  />
                </div>
                <div class="gallery-item">
                   <img class="gallery-image"
                  src="assets/images/Bathroom view 2 (Sreekant_ji) Ajay_ji.jpg"
                  alt=""
                  data-album="Bathroom"
                  />
                </div>
            </div>;
          } else if (exterior === "Office") {
            return <div className="gallery">
              <div class="gallery-item">
                   <img class="gallery-image"
                  src="assets/images/WhatsApp Image 2021-04-20 at 10.45.36 AM.jpeg"
                  alt=""
                  data-album="Bathroom"
                  />
                  </div>
                  <div class="gallery-item">
                   <img class="gallery-image"
                  src="assets/images/WhatsApp Image 2021-04-20 at 10.45.36 AM.jpeg"
                  alt=""
                  data-album="Bathroom"
                  />
                  </div>
                  <div class="gallery-item">
                   <img class="gallery-image"
                  src="assets/images/WhatsApp Image 2021-04-20 at 10.45.36 AM.jpeg"
                  alt=""
                  data-album="Bathroom"
                  />
                  </div>
            </div>;
          } else {
            return <div>catch all</div>;
          }
        })()}
      </div>
    </div>
  );
}