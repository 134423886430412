import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper";
import "./Service.css";

function Service() {
  // return (
  //   <div className="my-6">
  //     <div className="dot dot-1 bg-[url('https://img.freepik.com/free-vector/3d-perspective-style-diamond-shape-white-background_1017-27556.jpg?w=2000')] bg-center 0bject-cover h-[36rem]">
  //       <div className="py-8 w-[60%] my-0 mx-auto ">
  //         <div className="flex justify-between">
  //           <div className="w-80 object-cover">
  //             <img
  //               src="/assets/images/ceo.jpeg"
  //               alt=""
  //               className="pt-8 w-[32rem] h-[25.5rem] "
  //             />
  //             <span className="text-center">
  //               <p className="text-2xl font-bold text-slate-900 pt-2">Shrikant Sharma</p>
  //               <p className="text-sm font-semibold">Chief Executive Officer</p>
  //             </span>
  //           </div>
  //           <div className="w-2/3 ">
  //             <div className="mt-7 flex justify-between">
  //               <div>
  //                 <p className="text-2xl text-slate-700 w-96 font-bold">
  //                   Meet The Team That Makes All The Amazing Staff
  //                 </p>
  //               </div>
  //               <div>
  //                 <button className="btn btn-primary bg-black hover:bg-pink-600 text-white text-xs font-semibold py-2 px-2 team-swiper-button-prev">
  //                   <svg
  //                     xmlns="http://www.w3.org/2000/svg"
  //                     fill="none"
  //                     viewBox="0 0 24 24"
  //                     strokeWidth={1.5}
  //                     stroke="currentColor"
  //                     className="w-5 h-5"
  //                   >
  //                     <path
  //                       strokeLinecap="round"
  //                       strokeLinejoin="round"
  //                       d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z"
  //                     />
  //                   </svg>
  //                 </button>
  //                 <button className="ml-2 btn btn-primary bg-black hover:bg-pink-600 text-white text-xs font-semibold py-2 px-2 team-swiper-button-next">
  //                   <svg
  //                     xmlns="http://www.w3.org/2000/svg"
  //                     fill="none"
  //                     viewBox="0 0 24 24"
  //                     strokeWidth={1.5}
  //                     stroke="currentColor"
  //                     className="w-5 h-5"
  //                   >
  //                     <path
  //                       strokeLinecap="round"
  //                       strokeLinejoin="round"
  //                       d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z"
  //                     />
  //                   </svg>
  //                 </button>
  //               </div>
  //             </div>
  //             <div className="mt-7">
  //               <Swiper
  //                 slidesPerView={3}
  //                 centeredSlides={true}
  //                 spaceBetween={20}
  //                 grabCursor={true}
  //                 navigation={{
  //                   nextEl: ".team-swiper-button-next",
  //                   prevEl: ".team-swiper-button-prev",
  //                 }}
  //                 autoplay={{
  //                   delay: 3000,
  //                 }}
  //                 loop={true}
  //                 modules={[Navigation]}
  //                 className="mySwiper"
  //               >
  //                 <SwiperSlide>
  //                   <img
  //                     src="/assets/images/ceo.jpeg"
  //                     alt=""
  //                     className="w-[32rem] h-[18rem]"
  //                   />
  //                   <span className="text-center">
  //                     <p className="text-xl font-bold text-slate-900 pt-2">Shrikant Sharma</p>
  //                     <p className="text-xs font-semibold">CEO</p>
  //                   </span>
  //                 </SwiperSlide>
  //                 <SwiperSlide>
  //                   {" "}
  //                   <img
  //                     src="/assets/images/bhusan.jpeg"
  //                     alt=""
  //                     className="w-[32rem] h-[18rem]"
  //                   />
  //                   <span className="text-center">
  //                     <p className="text-xl font-bold text-slate-900 pt-2">Bhusan Kumar</p>
  //                     <p className="text-xs font-semibold">CFO cum Team Leader</p>
  //                   </span>
  //                 </SwiperSlide>
  //                 <SwiperSlide>
  //                   {" "}
  //                   <img
  //                     src="/assets/images/hema.jpeg"
  //                     alt=""
  //                     className="w-[32rem] h-[18rem]"
  //                   />
  //                   <span className="text-center">
  //                     <p className="text-xl font-bold text-slate-900 pt-2">Hema Kumari</p>
  //                     <p className="text-xs font-semibold">2D Designer</p>
  //                   </span>
  //                 </SwiperSlide>
  //                 <SwiperSlide>
  //                   {" "}
  //                   <img
  //                     src="/assets/images/L1.jpg"
  //                     alt=""
  //                     className="w-[32rem] h-[18rem]"
  //                   />
  //                   <span className="text-center">
  //                     <p className="text-xl font-bold text-slate-900 pt-2">Carol</p>
  //                     <p className="text-xs font-semibold">Of Design</p>
  //                   </span>
  //                 </SwiperSlide>
  //                 <SwiperSlide>
  //                   {" "}
  //                   <img
  //                     src="/assets/images/L1.jpg"
  //                     alt=""
  //                     className="w-[32rem] h-[18rem]"
  //                   />
  //                   <span className="text-center">
  //                     <p className="text-xl font-bold text-slate-900 pt-2">Carol</p>
  //                     <p className="text-xs font-semibold">Of Design</p>
  //                   </span>
  //                 </SwiperSlide>
  //                 <SwiperSlide>
  //                   {" "}
  //                   <img
  //                     src="/assets/images/L1.jpg"
  //                     alt=""
  //                     className="w-[32rem] h-[18rem]"
  //                   />
  //                   <span className="text-center">
  //                     <p className="text-xl font-bold text-slate-900 pt-2">Carol</p>
  //                     <p className="text-xs font-semibold">Of Design</p>
  //                   </span>
  //                 </SwiperSlide>
  //               </Swiper>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default Service;
