import Navbar from "../../components/Navbar/Navbar";
import BlogHere from "../../components/Blog/BlogHere";
import Footer from "../../components/Footer/Footer";

function BlogPage() {
  return (
    <div className="BlogPage">
      <Navbar />
      <div className="py-40">
        <BlogHere />
      </div>
      <Footer />
    </div>
  );
}

export default BlogPage;