import React from 'react';
import './Trunkey.css';

export default function Trunkey() {
  return (
    <div>
    <div className="-mt-64 Interior">
     <h1>   Turnkey Project</h1>
     </div>
     
     <section class="about-plan -mt-10">
         <div class="container">
        
             <div class="row" style={{display:"grid", gridTemplateColumns:"0.55fr 1fr"}}>                
                 <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                     <div class="inner-column -ml-44 md:ml-auto">
                         <div class="plan-title">
                             {/* <span class="plan-title font-bold text-sky-900">What We Plan</span> */}
                             <h2>Enhance Your Home Style <br/>With Better Living & Unique Design</h2>
                         </div>
                         <div class="text" style={{fontWeight:"600", color:"slategray"}}>Cross Angle Interiors provides end-to-end services to our clients.
                             From our team of experts offering the best designs and craftsmanship, to the final installation of your kitchen, 
                             what you need we have it.
                             We take care of everything from planning to execution. </div>
                         <ul class="list-style-one">
                             <li>End to end service</li>
                             <li>Planning to execution</li>
                             <li>Best customer service</li>
                             <li>Manage, coordinate, research, and planning </li>
                         </ul>
                         <div class="btn-box">
                             <a href="#!" class="theme-btn btn-style-one">Contact Us</a>
                         </div>
                     </div>
                 </div>
 
                
                 <div class="image-column col-lg-6 col-md-12 col-sm-12">
                     <div class="inner-column wow fadeInLeft hidden md:flex">
                         <figure class="image-1"><a href="#!" class="lightbox-image" data-fancybox="images"><img src="assets/images/187060068_4151996211523102_1780793583960844905_n.jpg" alt="" style={{borderRadius:"10px", boxShadow:"2px 2px 4px gray"}}/></a></figure>
                         <figure class="image-2"><a href="#!" class="lightbox-image" data-fancybox="images"><img src="assets/images/3.jpeg" alt="" width="350px" style={{borderRadius:"10px", boxShadow:"2px 2px 4px gray"}}/></a></figure>
                     </div>
                 </div>
             </div>
         </div>
     </section>
         </div>
  )
}
