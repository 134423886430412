import React from "react";
import "./Team.css";
function Index() {
  return (
    <div>
      <div className="-mt-64 teM">
        <h1> Meet Our Teams</h1>
      </div>
      <div className="container flex justify-center mx-auto pt-16">
        <div>
          <h1 className="xl:text-4xl text-4xl mt-10 font-bold leading-9 text-center text-slate-700 pb-6 sm:w-4/6 w-5/6 mx-auto">
            The Talented People Behind the Scenes of the Organization
          </h1>
        </div>
      </div>
      <div className="w-full px-10">
        <div className="container mx-auto">
          <div className="lg:flex md:flex xl:justify-between flex-wrap md:justify-around sm:justify-around lg:justify-around pt-10">
            <div className="xl:w-1/3 sm:w-1/2  mx-auto sm:max-w-xs mb-20 xl:max-w-sm lg:w-1/2 relative sol">
              <div className="bg-top bg-cover bg-no-repeat h-64">
                <img
                  src="/assets/images/Screenshot (120).png"
                  alt
                  className="h-full w-full overflow-hidden object-center rounded shadow"
                />
              </div>
              <div className="py-5 bg-white flex flex-col justify-center w-11/12 mx-auto absolute rounded shadow -mt-12 right-0 left-0">
                <p className="text-xl text-center font-bold text-slate-900  mb-1">
                  Shrikant Sharma
                </p>
                <p className="text-center text-base font-bold text-slate-700">
                  Chief Executive Officer
                </p>
                <p className="text-center text-base text-gray-600">
                  The Vice President, Operations is responsible for planning,
                  directing, and overseeing finance and operations activities in
                  the organization, and ensuring development.
                </p>
              </div>
            </div>

            <div className="xl:w-1/3 sm:w-1/2 mx-auto sm:max-w-xs mx-4 mt-64 md:mt-0 mb-40 md:mb-20 xl:max-w-sm lg:w-1/2 relative sol">
              <div className="bg-top bg-cover bg-no-repeat h-64">
                <img
                  src=" /assets/images/Screenshot (118).png"
                  alt
                  className="h-full w-full overflow-hidden object-cover rounded shadow"
                />
              </div>
              <div className="py-5 bg-white flex flex-col justify-center w-11/12 mx-auto absolute rounded shadow -mt-12 right-0 left-0">
                <p className="text-xl text-center font-bold text-slate-900 mb-1">
                  Chanda Sharma
                </p>
                <p className="text-center text-base font-bold text-slate-700">
                  Instructor
                </p>
                <p className="text-center text-base text-gray-600">
                  The Vice President, Operations is responsible for planning,
                  directing, and overseeing finance and operations activities in
                  the organization, and ensuring development.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 sm:w-1/2 mx-auto sm:max-w-xs mb-20 xl:max-w-sm mt-64 md:mt-0 lg:w-1/2 relative sol">
              <div className="bg-top bg-cover bg-no-repeat h-64">
                <img
                  src="/assets/images/hema.jpeg"
                  alt
                  className="h-full w-full overflow-hidden object-cover rounded shadow"
                />
              </div>
              <div className="py-5 bg-white flex flex-col justify-center w-11/12 mx-auto absolute rounded shadow -mt-12 right-0 left-0">
                <p className="text-xl text-center font-bold text-slate-900 mb-1">
                  Hema Kumari
                </p>
                <p className="text-center text-base font-bold text-slate-700">
                  2D Designer
                </p>
                <p className="text-center text-base text-gray-600">
                  The Vice President, Operations is responsible for planning,
                  directing, and overseeing finance and operations activities in
                  the organization, and ensuring development.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 sm:w-1/2 mx-auto  sm:max-w-xs mt-64 md:mt-24 mb-20 xl:max-w-sm lg:w-1/2 relative sol">
              <div className="bg-top bg-cover bg-no-repeat h-64">
                <img
                  src="/assets/images/bhusang.jpg"
                  alt
                  className="h-full w-full overflow-hidden object-center rounded shadow"
                />
              </div>
              <div className="py-5 bg-white flex flex-col justify-center w-11/12 mx-auto absolute rounded shadow -mt-12 right-0 left-0">
                <p className="text-xl text-center font-bold text-slate-900 mb-1">
                  Bhusan Kumar
                </p>
                <p className="text-center text-base font-bold text-slate-700">
                  CFO cum Team Leader
                </p>
                <p className="text-center text-base text-gray-600">
                  The Vice President, Operations is responsible for planning,
                  directing, and overseeing finance and operations activities in
                  the organization, and ensuring development.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 sm:w-1/2 mx-auto  sm:max-w-xs mx-4 mt-64 md:mt-24 mb-20 xl:max-w-sm lg:w-1/2 relative sol">
              <div className="bg-top bg-cover bg-no-repeat h-64">
                <img
                  src="/assets/images/shubham.JPG"
                  alt
                  className="h-full w-full overflow-hidden object-cover rounded shadow"
                />
              </div>
              <div className="py-5 bg-white flex flex-col justify-center w-11/12 mx-auto absolute rounded shadow -mt-12 right-0 left-0">
                <p className="text-xl text-center font-bold text-slate-900 mb-1">
                  Shubham Sharma
                </p>
                <p className="text-center text-base font-bold text-slate-700">
                  Chief Marketing Officer
                </p>
                <p className="text-center text-base text-gray-600">
                  The Vice President, Operations is responsible for planning,
                  directing, and overseeing finance and operations activities in
                  the organization, and ensuring development.
                </p>
              </div>
            </div>
            <div className="xl:w-1/3 sm:w-1/2 mx-auto  sm:max-w-xs mt-64 md:mt-24 mb-20 xl:max-w-sm lg:w-1/2 relative sol">
              <div className="bg-top bg-cover bg-no-repeat h-64">
                <img
                  src="/assets/images/WhatsApp Image 2023-01-05 at 20.27.50.jpg"
                  alt
                  className="h-full w-full overflow-hidden object-center rounded shadow"
                />
              </div>
              <div className="py-5 bg-white flex flex-col justify-center w-11/12 mx-auto absolute rounded shadow -mt-12 right-0 left-0">
                <p className="text-xl text-center font-bold text-slate-900 mb-1">
                  Souvik Dey
                </p>
                <p className="text-center text-base font-bold text-slate-700">
                  Marketing Executive
                </p>
                <p className="text-center text-base text-gray-600">
                  The Vice President, Operations is responsible for planning,
                  directing, and overseeing finance and operations activities in
                  the organization, and ensuring development.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
