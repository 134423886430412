import Navbar from "../../components/Navbar/Navbar";
import Services from "../../components/Service/Services";
import Footer from "../../components/Footer/Footer";

function ServicePage() {
  return (
    <div className="ServicePage">
      <Navbar />
      <div className="py-40">
        <Services />
      </div>
      <div className="md:-mt-40 -mt-52 ">
        <Footer />
        </div>
    </div>
  );
}

export default ServicePage;
