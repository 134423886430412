import React from "react";
import "./Partner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import useMediaQuery from "../../hooks/useMediaQuery";

export default function Partner() {
  SwiperCore.use([Autoplay]);
  const isDesktop = useMediaQuery("(min-width: 991px)");
  return (
    <div class="md:py-12 py-auto px-4 scroll-smooth">
      <div class="flex flex-col items-center justify-center">
        <h1 class="lg:text-5xl md:text-4xl text-2xl font-bold leading-10  text-slate-900">
          Our Trusted Partners
        </h1>
        <p class="text-base leading-normal text-center text-slate-600  mt-4 xl:w-1/2 w-10/12">
          We just got featured in the following magazines and it has been the
          most incredible journey. We work with the best fashion magazines
          across the world
        </p>
      </div>
      <div class="ml-auto md:ml-16  items-center justify-center mt-10">
        <Swiper
          slidesPerView={isDesktop ? 7 : 2}
          centeredSlides={true}
          spaceBetween={10}
          grabCursor={true}
          // navigation={{
          //   nextEl: ".team-swiper-button-next",
          //   prevEl: ".team-swiper-button-prev",
          // }}
          autoplay={{
            delay: 3000,
          }}
          loop={true}
          // modules={[Navigation]}
          className="mySwiper"
        >
          <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 gap-6 w-full los">
            <SwiperSlide>
              <div class="md:w-48 rounded-md transition ease-in-out delay-150 hover:-translate-y-1 w-full h-32 hover:bg-red-400 bg-gray-100 flex items-center justify-center">
                <h1 className="text-2xl font-bold leading-9 text-center text-slate-900">
                  Hyatt
                </h1>
                {/* <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/cloud-logo-5-svg1.svg" alt="Hyatt"/> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="md:w-48 rounded-md transition ease-in-out delay-150 hover:-translate-y-1 w-full h-32 hover:bg-red-400 bg-gray-100 flex items-center justify-center">
                <h1 className="text-2xl font-bold leading-9 text-center text-slate-900">
                  Ojas
                </h1>
                {/* <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/cloud-logo-5-svg2.svg" alt="dribble"/> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="md:w-48 rounded-md transition ease-in-out delay-150 hover:-translate-y-1 w-full h-32 hover:bg-red-400 bg-gray-100 flex items-center justify-center">
                <h1 className="text-2xl font-bold leading-9 text-center text-slate-900">
                  The Lalit
                </h1>
                {/* <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/cloud-logo-5-svg3.svg" alt="amazon"/> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="md:w-48 rounded-md transition ease-in-out delay-150 hover:-translate-y-1 w-full h-32 hover:bg-red-400 bg-gray-100 flex items-center justify-center">
                <h1 className="text-2xl font-bold leading-9 text-center text-slate-900">
                  Taal Kutir
                </h1>
                {/* <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/cloud-logo-5-svg4.svg" alt="linkedIn"/>                 */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="md:w-48 rounded-md transition ease-in-out delay-150 hover:-translate-y-1 w-full h-32 hover:bg-red-400 bg-gray-100 flex items-center justify-center">
                <h1 className="text-2xl font-bold leading-9 text-center text-slate-900">
                  ITC{" "}
                </h1>
                {/* <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/cloud-logo-5-svg5.svg" alt="blackberry"/> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="md:w-48 rounded-md transition ease-in-out delay-150 hover:-translate-y-1 w-full h-32 hover:bg-red-400 bg-gray-100 flex items-center justify-center">
                <h1 className="text-2xl font-bold leading-9 text-center text-slate-900">
                  JW Marriott
                </h1>
                {/* <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/cloud-logo-5-svg6.svg" alt="microsoft"/> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="md:w-48 rounded-md transition ease-in-out delay-150 hover:-translate-y-1 w-full h-32 hover:bg-red-400 bg-gray-100 flex items-center justify-center">
                <h1 className="text-2xl font-bold leading-9 text-center text-slate-900">
                  Presdiency
                </h1>
                {/* <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/cloud-logo-5-svg6.svg" alt="microsoft"/> */}
              </div>
            </SwiperSlide>
          </div>
        </Swiper>
      </div>
    </div>
  );
}
