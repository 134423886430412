import "./Blog.css";

function Blog() {
  return (
    <div className="py-24 bg-[url('/public/assets/images/about-banner.jpeg')] bg-center min-h-[25rem] photo">
      <div className="w-[60%] -mt-16 my-0 mx-auto">
        <p className="text-center font-bold text-xl uppercase text-teal-700">Our Blog</p>
        <p className="text-center font-semibold mt-1 text-3xl">Recent News</p>
        <div className="mt-10 flex justify-between flex-wrap">
       < a href="/blog"> <div className="w-72 slight">
            <img src="/assets/images/L2.jpg" alt="" className="w-72 h-72" />
            <p className="mt-4 text-pink-700 mr-1">26th July, 2022</p>
            <p className="text-xl font-semibold">
              Capturing the Essence of Home in Ultra Modern Living
            </p>
          </div></a>
          < a href="/blog"> <div className="w-72 mt-8 md:mt-0 slight1">
            <img src="/assets/images/L3.jpg" alt="" className="w-72 h-72" />
            <p className="mt-4 text-pink-700 mr-1">26th July, 2022</p>
            <p className="text-xl font-semibold">
              Capturing the Essence of Home in Ultra Modern Living
            </p>
          </div></a>
          < a href="/blog">  <div className="w-72 mt-8 md:mt-0 slight2">
            <img src="/assets/images/L4.jpg" alt="" className="w-72 h-72" />
            <p className="mt-4 text-pink-700 mr-1">26th July, 2022</p>
            <p className="text-xl font-semibold">
              Capturing the Essence of Home in Ultra Modern Living
            </p>
          </div></a>
        </div>
      </div>
    </div>
  );
}

export default Blog;
