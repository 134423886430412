import "./About.css";
import { useNavigate } from "react-router-dom";

function About() {
  const navigate = useNavigate();
  return (
    <div className="mt-32 md:mt-2 my-2 bg-[url('/public/assets/images/about-banner.jpeg')] scroll-smooth bg-center min-h-[25rem] photo pillars">
      <div className="py-40 w-[70%] my-0 mx-auto md:flex">
        <div class="w-[100%] md:w-[90%] my-0 -mt-52 md:-mt-14 mr-24">
          <p className="font-bold text-xl uppercase text-center md:text-left text-red-500">
            About Us
          </p>
          <p className="font-bold mt-4 text-slate-700 text-xl text-justify md:text-3xl">
            We Are Creative Solutions For Smart And Efficient Home Interiors &
            Exteriors With Superior Quality
          </p>

          <p class="mt-4 text-lg text-justify">
            <span class="text-slate-900 font-semibold mr-1">
              <u className="no-underline">Cross Angle Interior</u>
            </span>
            is a company specializing in all things interior design, exterior
            design and home renovation. We offer a variety of services including
            home staging, room design, 3D renders and more. If you are in the
            process of building or renovating your home, you've come to the
            right place! We offer a wide range of services that can be
            customized to suit your needs and budget. Our team of designers and
            architects will work with you every step of the way from coming up
            with ideas to executing them. Design an interior space that's truly
            personalized for you. Contact us today for your{" "}
            <span class="text-slate-700 font-semibold mr-1">
              FREE CONSULTATION!
            </span>
          </p>
          <div
            className="mt-8 about-button slide_right"
            onClick={() => navigate("/about")}
          >
            View More
          </div>
        </div>
        <div className="w-96 hidden md:block ml-16">
          <img
            src="/assets/images/L2.jpg"
            alt=""
            className="absolute w-[28rem] h-[24rem] -mt-24  -ml-10"
          />
          <img
            src="/assets/images/L4.jpg"
            alt=""
            className="absolute w-[20rem] h-[18rem] mt-32 -ml-32 border-[0.7rem] border-white"
          />
          <img
            src="/assets/images/L3.jpg"
            alt=""
            className="absolute w-[13.5rem] h-[10rem] mt-[15.2rem] ml-[12rem] border-t-[0.7rem] border-white"
          />
        </div>
      </div>
    </div>
  );
}

export default About;
