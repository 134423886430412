import React from "react";
import "./About.css";
import ReactPlayer from 'react-player'
import YoutubeEmbed from "./YoutubeEmbed";

const About2 = () => {
    return (
        <div>
        <div className="about1 -mt-40 scroll-smooth ">
        <h1>     About Us</h1>
         </div> 
        <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 scroll-smooth">
            <div className="">
              
                <h2 className="font-bold text-slate-800 lg:text-4xl text-3xl lg:leading-10 leading-9 mt-2 scroll-smooth ">Choose The Best Interior Designer For Your Home- Modern and Contemporary Design</h2>
                <p className="font-normal text-base leading-6 text-slate-600 mt-6 scroll-smooth text-justify">Cross Angle Interior provides you with beautiful, functional and sustainable interior spaces for both residential and commercial purposes. 
                We believe in understanding and creating unique and personalized spaces for our clients that are both comfortable and functional.
                We pride ourselves on our attention to detail, customer service and professionalism. The journey of Cross Angle Interior started with abrupt thought of doing something extraordinary, approachable and admirable concept development for each and every segments of clients those having dream to be look smart be smart and live smart in this beautiful world. 
                <br/><br/>In this journey we observed everyone has a common desire that to be the proud owner of a beautiful house, which they can decorate with their own wish and thoughts. And, here the breakthrough, we initiate to make your dream home comes into reality. Cross Angel Interior at Kalimati road in Jamshedpur is your one-stop destination to make dream in reality of having beautiful house with smart living. Being one of the leading player in the field of Architects in the Jamshedpur we provide complete satisfaction to our customers. We work passionately and wholeheartedly for our all clients to give them a lavish and admirable comfort which they can cherish for lifetime.
                <br/><br/> With this belief that our customer satisfaction comes first, we provide our 
clients with the personalized and customized design that goes beyond their imagination. With every new client we try to lead with an exclusive design that reflects the taste, desire and personality.
</p>
            </div>

            <div className="lg:mt-14 sm:mt-10 mt-12 lot scroll-smooth">
                <img className="lg:block hidden w-full h-[35rem]" src="assets/images/c2bc5c00-4334-4ff9-80c7-7387700cb633.jpg" alt="Group of people Chilling" />
                <img className="lg:hidden sm:block hidden w-full" src="assets/images/c2bc5c00-4334-4ff9-80c7-7387700cb633.jpg" alt="Group of people Chilling" />
                <img className="sm:hidden block w-full" src="assets/images/c2bc5c00-4334-4ff9-80c7-7387700cb633.jpg" alt="Group of people Chilling" />
            </div>

            <div className="lg:mt-16 sm:mt-12 mt-16 flex lg:flex-row justify-between flex-col lg:gap-8 gap-12">
                <div className="w-full xl:w-5/12 lg:w-6/12">
                    <h2 className="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-slate-800">Our Story</h2>
                
                    <p className="font-normal text-base leading-6 text-gray-600 mt-6 text-justify">We give our complete focus towards the important of understanding the particular needs and wishes of our clients and provide the best services to them.
                     Cross Angel Interior is known to be the one of the prime full-service architectural firm in Jamshedpur. 
                     We have earned this reputation by providing highest quality of professional services to our each and every client.
                     
                     By keeping in mind the social, economic and environmental issues relevant to each project.
                      We construct our work which is innovative and unique. <br/><br/> With the experience of more than 10 years in this field, we have a team of skilled and competent architects, interior designer, civil engineers, urban/town planner, landscape artist, CAD operators, interior decorators and 3D and graphic designers and support team. The journey we started is going on and some milestone we crossed and many more are awaiting. </p>
                </div>
                <div className="lg:flex items-center w-full h-[100%]  mt-6 lg:w-1/2 scroll-smooth" >
                <YoutubeEmbed embedId="gJMCIaI7nKg" />
               
                </div>
            </div>
        </div>
       </div>
    );
};

export default About2;
